import React, { useEffect, useState } from 'react';
import { useNegocios } from '../../../../Hooks/useNegocios';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CircularProgress, Box, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { INegocioCambioRequest, INegocioConsorcioRequest, INegociosGroupResponse } from '../../../../Interface/interfaceNegocios';
import { formatEstrategiaConsorcio, formatStatusNegociosCambio, truncateString } from '../../../../Helpers/formatters';

export const OthersNegocios: React.FC = () => {
  const { negocios, loading, fetchNegociosByClienteId } = useNegocios();
  const { userId: clientId } = useParams();
  const [selectedNegocio, setSelectedNegocio] = useState<INegocioCambioRequest | INegocioConsorcioRequest | null>(null);
  const [openNegocioDetail, setOpenNegocioDetail] = useState(false);

  useEffect(() => {
    fetchNegociosByClienteId(clientId ?? '');
  }, [clientId]);

  const handleCardClick = (negocio: INegocioCambioRequest | INegocioConsorcioRequest) => {
    setSelectedNegocio(negocio);
    setOpenNegocioDetail(true);
  };

  const handleCloseModal = () => {
    setOpenNegocioDetail(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const negociosGroup = negocios as INegociosGroupResponse;

  const totalVolumeCambio = negociosGroup?.NegociosCambio.reduce(
    (sum, negocio) => sum + (negocio.Cambio.ValorReais ?? 0),
    0
  );

  const totalVolumeConsorcio = negociosGroup?.NegociosConsorcio.reduce(
    (sum, negocio) => sum + (negocio.Consorcio.ValorFinanceiro ?? 0),
    0
  );

  const totalNegociosCambio = negociosGroup?.NegociosCambio.length || 0;
  const totalNegociosConsorcio = negociosGroup?.NegociosConsorcio.length || 0;

  const renderNegocioCambioCard = (negocio: INegocioCambioRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant="h6" color='primary'>{truncateString(negocio.Negocio.Descricao, 50)}</Typography>
        </Box>
        <Box display="flex" flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            Volume Operado: <strong style={{ color: 'green' }}>{negocio.Cambio.ValorReais ? negocio.Cambio.ValorReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</strong>
          </Typography>
          <Typography fontSize={12} color='gray'>Moeda: {negocio.Cambio.Moeda ? negocio.Cambio.Moeda : '-'}</Typography>
          <Typography fontSize={12} color='gray'>País Parceiro: {negocio.Cambio.PaisParceiro ? negocio.Cambio.PaisParceiro : '-'}</Typography>
          <Typography fontSize={12} color='gray'>Status: {negocio.Negocio.Status ? formatStatusNegociosCambio(negocio.Negocio.Status) : '-'}</Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderNegocioConsorcioCard = (negocio: INegocioConsorcioRequest) => (
    <Card
      key={negocio.Negocio.Id}
      sx={{
        marginBottom: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
        },
      }}
      onClick={() => handleCardClick(negocio)}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <CurrencyExchangeIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
          <Typography variant="h6" color='primary'>{truncateString(negocio.Negocio.Descricao, 50)}</Typography>
        </Box>
        <Box display="flex" flexDirection='column' gap={1} mt={1} ml={1}>
          <Typography fontSize={12} color='gray'>
            Valor Financeiro: <strong style={{ color: 'green' }}>{negocio.Consorcio.ValorFinanceiro ? negocio.Consorcio.ValorFinanceiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</strong>
          </Typography>
          <Typography fontSize={12} color='gray'>Estratégia: {negocio.Consorcio.Estrategia ? formatEstrategiaConsorcio(negocio.Consorcio.Estrategia) : '-'}</Typography>
          <Typography fontSize={12} color='gray'>Tipo: {negocio.Consorcio.Tipo ? negocio.Consorcio.Tipo : '-'}</Typography>
          <Typography fontSize={12} color='gray'>Status: {negocio.Negocio.Status ? formatStatusNegociosCambio(negocio.Negocio.Status) : '-'}</Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderEmptyState = (message: string) => (
    <Box>
      <Typography variant="body1" align="center" sx={{ opacity: 0.5, my: 5, fontSize: 10 }}>
        {message}
      </Typography>
    </Box>
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h5" align="center">Câmbio</Typography>
          <Typography variant="subtitle1" align="center" fontSize={10} sx={{ color: 'gray', mb: 2 }}>
            {totalVolumeCambio?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {totalNegociosCambio} negócio(s)
          </Typography>
          {totalNegociosCambio > 0
            ? negociosGroup.NegociosCambio.map(renderNegocioCambioCard)
            : renderEmptyState('Nenhum negócio de câmbio foi criado')}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h5" align="center">Consórcio</Typography>
          <Typography variant="subtitle1" align="center" fontSize={10} sx={{ color: 'gray', mb: 2 }}>
            {totalVolumeConsorcio?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {totalNegociosConsorcio} negócio(s)
          </Typography>
          {totalNegociosConsorcio > 0
            ? negociosGroup.NegociosConsorcio.map(renderNegocioConsorcioCard)
            : renderEmptyState('Nenhum negócio de consórcio foi criado')}
        </Grid>
      </Grid>

      <Dialog open={!!selectedNegocio && openNegocioDetail} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle variant='h4'>
          Detalhes do Negócio
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {selectedNegocio && (
          <DialogContent dividers>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <Typography><strong>Descrição:</strong> {selectedNegocio.Negocio.Descricao}</Typography>
              {'Cambio' in selectedNegocio && (
                <>
                  <Typography><strong>Volume Operado:</strong> {selectedNegocio.Cambio.ValorReais ? selectedNegocio.Cambio.ValorReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</Typography>
                  <Typography><strong>Moeda:</strong> {selectedNegocio.Cambio.Moeda}</Typography>
                  <Typography><strong>País Parceiro:</strong> {selectedNegocio.Cambio.PaisParceiro}</Typography>
                  <Typography><strong>Pessoa Física:</strong> {selectedNegocio.Cambio.PessoaFisica ? 'Sim' : 'Não'}</Typography>
                  <Typography><strong>Saída Fiscal:</strong> {selectedNegocio.Cambio.SaidaFiscal ? 'Sim' : 'Não'}</Typography>
                </>
              )}
              {'Consorcio' in selectedNegocio && (
                <>
                  <Typography><strong>Estratégia:</strong> {selectedNegocio.Consorcio.Estrategia ? formatEstrategiaConsorcio(selectedNegocio.Consorcio.Estrategia) : '-'}</Typography>
                  <Typography><strong>Tipo:</strong> {selectedNegocio.Consorcio.Tipo}</Typography>
                  <Typography><strong>Valor Financeiro:</strong> {selectedNegocio.Consorcio.ValorFinanceiro?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                </>
              )}
              <Typography><strong>Data Início:</strong> {new Date(selectedNegocio.Negocio.DataInicio).toLocaleDateString()}</Typography>
              <Typography><strong>Data Fechamento:</strong> {selectedNegocio.Negocio.DataFechamento ? new Date(selectedNegocio.Negocio.DataFechamento).toLocaleDateString() : '-'}</Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};
