import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { CircularProgress, Box, Typography, Container, Autocomplete, TextField, Stack } from '@mui/material';
import { useKanbanNegociosContext } from '../../../Hooks/useKanbanNegocios';
import { KanbanColumnCambio } from './KanbanColumn';
import { KanbanSearchInputCambio } from './SearchInput';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

export const KanbanBoardCambio: React.FC = () => {
  const { loading, onDragEnd, filteredKanbanList, users, setUsuarioId, usuarioId, isAdminCambio } = useKanbanNegociosContext();

  return (
    <Container maxWidth='xl'>
      <Box my={2} display='flex' flexDirection='column'>
        <Typography variant='h3' my={1}>Funil comercial de Câmbio</Typography>
        <Stack alignItems='center' direction='row' spacing={6} width='max-content'
          sx={{
            px: 5,
            backgroundColor: '#fff',
            borderRadius: 1,
            height: '80px',
            position: 'relative',
          }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <WorkOutlineIcon sx={{ color: '#bcbbce' }} />
            <Typography variant='h5'>{Object.values(filteredKanbanList).reduce((acc, column) => acc + column.data.length, 0)}</Typography>
          </Stack>
          {isAdminCambio && (
            <Autocomplete
              size='small'
              options={users}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label='Selecione um usuário' variant='outlined' />
              )}
              value={users.find(user => user.id === usuarioId) || null}
              noOptionsText='Nenhuma opção disponível'
              onChange={(event, newValue) => {
                setUsuarioId(newValue ? newValue.id : null);
              }}
              sx={{ width: 230, mb: 2 }}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{
        backgroundColor: '#ffffff',
        borderRadius: 1, width: 'max-content'
      }}>
        <Box
          sx={{
            pl: 3,
            pt: 3,
          }}
        >
          <KanbanSearchInputCambio />
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box display='flex' overflow='auto' p={2}>
            {Object.entries(filteredKanbanList).map(([status, column]) => {
              const typedColumn = column as { name: string; data: any[] };
              return (
                <KanbanColumnCambio
                  key={status}
                  status={status}
                  columnName={typedColumn.name}
                  negocios={typedColumn.data}
                  loading={loading}
                />
              );
            })}
          </Box>
        </DragDropContext>
      </Box>
    </Container>
  );
};