import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Chip, Tooltip } from '@mui/material';
import { interfaceClientSeguros } from '../../../Interface/interfaceClient';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useKanbanInsuranceContext } from '../../../Hooks/useKanbanInsurance';
import { formatCurrencyBRL } from '../../../Helpers/formatters';

interface Props {
  client: interfaceClientSeguros;
  index: number;
}

export const KanbanItem: React.FC<Props> = ({ client, index }) => {
  const { changeClientStatusWithReason } = useKanbanInsuranceContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [reason, setReason] = useState('');

  const draggableId = client.Id ? client.Id : `client-${index}`;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setReason('');
  };

  const handlePerdeu = () => {
    const newStatus: interfaceClientSeguros['StatusSeguros'] = client.StatusSeguros === 'EMITIDO' ? 'CANCELADO' : 'PERDEU';
    changeClientStatusWithReason(client, newStatus, reason);
    handleCloseDialog();
  };

  return (
    <>
      <Draggable draggableId={draggableId} index={index}>
        {(provided) => (
          <Grid item xs={12} mx={2}>
            <Card
              elevation={2}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{ position: 'relative' }}
            >
              <CardContent>
                <Box display="flex" alignItems="center">
                  <PersonIcon style={{ marginRight: 8 }} />
                  <Typography variant='h6' fontSize={14}>{client.NomeCompleto}</Typography>
                </Box>
                <Typography variant='body1' fontSize={13} fontWeight='bold' sx={{ my: 1 }}>
                  {client.AssessorInfos ? client.AssessorInfos.NomeCompleto : '-'}
                </Typography>
                {client.PASeguros && <Tooltip title='PA' disableInteractive><Typography variant='body1' fontSize={13} fontWeight='bold' sx={{ my: 1 }}>
                  {formatCurrencyBRL(client.PASeguros)}
                </Typography>
                </Tooltip>}

                <Box display="flex" justifyContent='center' gap={1}>
                  {
                    client.InteresseReuniaoSegurosDeVida && <Tooltip title='Seguro de Vida'><Chip label='SV' size='small' color='primary' /></Tooltip>
                  }
                  {
                    client.InteresseReuniaoPlanoDeSaude && <Tooltip title='Plano de Saúde'><Chip label='PS' size='small' color='info' /></Tooltip>
                  }
                  {
                    client.InteresseReuniaoPlanejamentoPatrimonial && <Tooltip title='Planejamento Patrimonial'><Chip label='PPS' size='small' color='secondary' /></Tooltip>
                  }
                  {
                    client.InteresseReuniaoOutros && <Chip label='Outros' size='small' />
                  }
                </Box>
              </CardContent>
              <IconButton
                sx={{ position: 'absolute', right: 0, top: 0 }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => {
                  let url = `/app/perfil-cliente/${client.Id}`
                  window.open(url, '_blank', 'noopener,noreferrer');
                  handleClose();
                }}>Abrir perfil</MenuItem>
                <MenuItem onClick={handleOpenDialog}>Perdeu</MenuItem>
              </Menu>
            </Card>
          </Grid>
        )}
      </Draggable>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Motivo da Perda</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, insira o motivo pelo qual o cliente foi perdido.
          </DialogContentText>
          <TextField
            sx={{ mt: 2 }}
            multiline
            rows={4}
            autoFocus
            label="Motivo"
            fullWidth
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} size='small'>
            Cancelar
          </Button>
          <Button onClick={handlePerdeu} variant='contained' size='small'>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
