import { Grid, Stepper, Step, Checkbox, FormControlLabel, styled, Box, Typography, StepButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useClientContext } from './ClientContext';
import finishedStepperMedal from '../../../Assets/Images/finished-stepper-medal.png'
import stepperMedal from '../../../Assets/Images/stepper-medal.png'
import { NewFeatureIcon } from '../NewFeatureIcon';
import { useState } from 'react';
import { InsuranceDialog } from './Dialogs/SendToInsurance';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

interface CustomStepIconProps {
  active?: boolean;
  completed?: boolean;
  status?: 'LEAD' | 'R1' | 'AGUARDANDO' | 'FECHADO' | 'EM_ANALISE' | 'EMITIDO' | 'CANCELADO' | 'PERDEU' | null;
  stepName?: string;
}

const CustomStepIconRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'completed' && prop !== 'status' && prop !== 'stepName',
})<CustomStepIconProps>(({ theme, completed, active, status, stepName }) => {
  let backgroundColor = theme.palette.grey[400];
  let color = '#fff';

  let isInsurance = stepName === 'Seguros';

  if (isInsurance) {
    if (status === 'CANCELADO' || status === 'PERDEU') {
      backgroundColor = theme.palette.error.main;
      color = theme.palette.error.contrastText;
    } else if (status === 'EMITIDO') {
      backgroundColor = theme.palette.warning.main;
    } else if (status) {
      backgroundColor = theme.palette.info.main;
    }
  }

  return {
    backgroundColor: (completed && !isInsurance) ? theme.palette.primary.main : backgroundColor,
    color: (completed && !isInsurance) ? '#fff' : color,
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(active && {
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}`,
    }),
  };
});

const CustomStepIcon: React.FC<CustomStepIconProps> = (props) => {
  const { completed, status, stepName } = props;

  let icon = <CloseIcon fontSize='small' />;
  if (stepName === 'Seguros') {
    if (status === 'CANCELADO' || status === 'PERDEU') {
      icon = <CloseIcon fontSize='small' />;
    } else if (status === 'EMITIDO') {
      icon = <CheckIcon fontSize='small' />;
    } else if (status) {
      icon = <HourglassTopIcon fontSize='small' />;
    }
  } else {
    icon = completed ? <CheckIcon fontSize='small' /> : <CloseIcon fontSize='small' />;
  }

  return <CustomStepIconRoot {...props}>{icon}</CustomStepIconRoot>;
};

export const ObjectiveSteps: React.FC = () => {
  const { listServices, toggleServiceStatus, client } = useClientContext();

  const sortedListServices = listServices.sort((a, b) => {
    // Verifica se ambos os serviços têm DataAdesao
    const dateAExists = !!a.DataAdesao;
    const dateBExists = !!b.DataAdesao;

    // Se ambos ou nenhum tem DataAdesao, mantém a ordem original (considerando que isso pode ser ajustado conforme a necessidade)
    if (dateAExists === dateBExists && a.DataAdesao) {
      if (dateAExists) {
        // Se ambos têm DataAdesao, compara as datas
        const dateA = new Date(a.DataAdesao!);
        const dateB = new Date(b.DataAdesao!);
        return dateA.getTime() - dateB.getTime();
      } else {
        // Se nenhum tem DataAdesao, pode optar por deixar na ordem atual ou aplicar outro critério
        return 0;
      }
    } else if (dateAExists && !dateBExists) {
      // Se A tem DataAdesao e B não tem, A vem antes de B
      return -1;
    } else {
      // Se B tem DataAdesao e A não tem, B vem antes de A
      return 1;
    }
  });

  const allCompleted = sortedListServices.filter(service => service.Tipo === 'step').every(service => !!service.DataAdesao);

  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);

  const handleOpenModal = () => {
    setInsuranceModalOpen(true);
  };

  return (
    <Grid container item xs={12} md={5}>
      <Grid item xs={12} position='relative'>
        <Typography variant="h5" fontSize={15} mb={2} textAlign='center'>
          Gerenciais
        </Typography>
        <Box position='absolute' right={0} top={0}>
          <NewFeatureIcon title='Agora é fácil alterar os investimentos! Clique nos passos para ajustar. 😀' />
        </Box>
      </Grid>
      <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
        <Stepper alternativeLabel nonLinear activeStep={-1}>
          {
            sortedListServices.map((step) => {
              return step.Tipo === 'step' ? <Step key={step.ServicoId} completed={!!step.DataAdesao}>
                <StepButton
                  onClick={step.Nome === 'Seguros' ? handleOpenModal : () => toggleServiceStatus(step)}
                  icon={<CustomStepIcon completed={!!step.DataAdesao} status={client?.StatusSeguros ?? null} stepName={step.Nome} />}
                  sx={{
                    '& .MuiStepLabel-label': {
                      fontWeight: 'bold',
                    }
                  }}
                >
                  {step.Nome}
                </StepButton>
              </Step> : null;
            })
          }
        </Stepper>
        <Box ml={4}>
          {allCompleted ? (
            <img src={finishedStepperMedal} alt='Finished Medal' style={{ height: 70 }} />
          ) : (
            <img src={stepperMedal} alt='Stepper Medal' style={{ height: 70 }} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
        {
          sortedListServices.map(step => {
            return step.Tipo === 'check' ? <FormControlLabel
              sx={{ cursor: 'initial' }}
              onClick={() => toggleServiceStatus(step)}
              control={<Checkbox size='small' checked={!!step.DataAdesao} />}
              label={<Typography variant='body2' fontSize={11}>{step.Nome}</Typography>}
              labelPlacement='end'
            /> : <></>;
          })
        }
      </Grid>

      <InsuranceDialog open={insuranceModalOpen} setOpen={setInsuranceModalOpen} />
    </Grid >
  );
};
