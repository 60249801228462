import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StorageHelper from "../../../Services/Helpers/StorageHelper"
import { useEffect, useState } from "react"
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddCommentIcon from '@mui/icons-material/AddCommentOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import {
  Link,
  Navigate
} from 'react-router-dom';

import Typography from '@mui/material/Typography'
import SchoolIcon from '@mui/icons-material/School';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import { BiBarChartSquare } from 'react-icons/bi';
import { AiOutlineFunnelPlot } from 'react-icons/ai';
import { MdPersonAddAlt } from 'react-icons/md';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { BiTask } from 'react-icons/bi';
import { Collapse, ListItemButton } from '@mui/material';
import { DialogNovoLeadComponent } from './DialogNovoLeadComponent';
import { usePermissions } from '../../../Hooks/usePermissionsUser';

const paths = ["", "manage-lyrics", "sheets-musics"]

const SideBarComponent = (params: any) => {
  let open = params.open

  const storageHelper = new StorageHelper();
  const [usuarioRole, setUsuarioRole] = useState<String>()
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const { permissions } = usePermissions();

  const toggleAdmin = () => {
    setIsAdminOpen(!isAdminOpen);
  };


  useEffect(() => {
    setUsuarioRole(storageHelper.GetUsuarioRole())
  }, [])

  const Logout = () => {
    storageHelper.Logout();
    return <Navigate to="/" />
  }

  // Dialog New Lead
  const [openDialogNewLead, setOpenDialogNewLead] = useState(false);

  const handleClickOpen = () => {
    setOpenDialogNewLead(true);
  };

  const handleClose = () => {
    setOpenDialogNewLead(false);
  };

  const showFunilComercialSeguros = permissions.some(permission =>
    permission.PermissionName === 'Seguros' || permission.PermissionName === 'AdminSeguros'
  ) || usuarioRole === 'Administrador';

  const showFunilComercialCambio = permissions.some(permission => (permission.PermissionName === 'Câmbio' || permission.PermissionName === 'AdminCâmbio')) || usuarioRole === 'Administrador';
  const showFunilComercialConsorcios = permissions.some(permission => (permission.PermissionName === 'Consórcio' || permission.PermissionName === 'AdminConsórcio')) || usuarioRole === 'Administrador';
  const hasTombamentoPermission = permissions.some(permission => permission.PermissionName === 'Tombamento');

  return (
    <>
      <List sx={{ color: "red" }}>
        <ListItem sx={{ pl: 2 }} button key="dashboard-pbi" component={Link} to="dashboard-pbi">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : 'auto',
              justifyContent: 'center',
            }}
          >
            <BiBarChartSquare style={{ height: 23, width: 23, color: "#a5a4bf" }} />
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Dashboard Power BI</Typography>} />
        </ListItem>

        <ListItem sx={{ pl: 2 }} button key="atividades" component={Link} to="atividades">
          <ListItemIcon sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
          }}
          >
            <InsertInvitationIcon style={{ height: 23, width: 23, color: "#a5a4bf" }} />
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Atividades</Typography>} />
        </ListItem>
        <ListItem sx={{ pl: 2 }} button key="dashboard" component={Link} to="tasks-list">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : 'auto',
              justifyContent: 'center',
            }}
          >
            <BiTask style={{ height: 23, width: 23, color: "#a5a4bf" }} />
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Tarefas</Typography>} />
        </ListItem>
        <Divider />
        <ListItemButton sx={{ pl: 2 }} key="kanban" component={Link} to="kanban">
          <Tooltip title="Funil Comercial" placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 'auto',
                justifyContent: 'center',
              }}
            >
              <AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Funil comercial</Typography>} />
        </ListItemButton>

        {showFunilComercialSeguros && <ListItemButton sx={{ pl: 2 }} key="kanban-insurance" component={Link} to="kanban-insurance">
          <Tooltip title="Funil Comercial de Seguros" placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 'auto',
                justifyContent: 'center',
              }}
            >
              <AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Funil comercial Seguros</Typography>} />
        </ListItemButton>}

        {showFunilComercialCambio && <ListItemButton sx={{ pl: 2 }} key="kanban-cambio" component={Link} to="kanban-cambio">
          <Tooltip title="Funil Comercial de Câmbio" placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 'auto',
                justifyContent: 'center',
              }}
            >
              <AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Funil comercial Câmbio</Typography>} />
        </ListItemButton>}

        {showFunilComercialConsorcios && <ListItemButton sx={{ pl: 2 }} key="kanban-consorcios" component={Link} to="kanban-consorcios">
          <Tooltip title="Funil Comercial de Consórcios" placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 'auto',
                justifyContent: 'center',
              }}
            >
              <AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Funil comercial Consórcios</Typography>} />
        </ListItemButton>}

        <ListItemButton sx={{ pl: 2 }} key="clientes" component={Link} to="clientes">
          <Tooltip title="Clientes" placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 'auto',
                justifyContent: 'center',
              }}
            >
              <AccountBalanceWalletIcon style={{ height: 23, width: 23, color: "#a5a4bf" }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Clientes</Typography>} />
        </ListItemButton>
        <Box sx={{ pl: 1, backgroundColor: "#481d6b" }} >
          <List component="div" disablePadding>
          </List>
        </Box>
        <Divider />
        <a href="https://uxp.skore.io/pt/login?redirect=%2Fpt%2Fpages%2Fu_marco_investimentos" target="_blank" >
          <ListItem sx={{ pl: 2 }} button key="squads" >
            <ListItemIcon sx={{
              minWidth: 0,
              mr: open ? 2 : 'auto',
              justifyContent: 'center',
            }}
            >
              <SchoolIcon sx={{ color: "#a5a4bf" }} />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Formação</Typography>} />
          </ListItem>
        </a>
        <a href="https://app.pipefy.com/portals/32a605dd-a56b-4c55-bbed-c240baebd357" target="_blank" >
          <ListItem sx={{ pl: 2 }} button key="squads" >
            <ListItemIcon sx={{
              minWidth: 0,
              mr: open ? 2 : 'auto',
              justifyContent: 'center',
            }}
            >
              <AddCommentIcon sx={{ color: "#a5a4bf" }} />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Solicitações</Typography>} />
          </ListItem>
        </a>
        {(usuarioRole === "Administrador" || hasTombamentoPermission) && (
          <>
            <ListItem button onClick={toggleAdmin}>
              <ListItemIcon>
                <SupervisorAccountIcon sx={{ color: "#a5a4bf" }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Administração</Typography>} />
              {isAdminOpen ? <ExpandLessIcon color='primary' /> : <ExpandMoreIcon color='primary' />}
            </ListItem>
            <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
              {usuarioRole === "Administrador" && <>
                <ListItem sx={{ pl: 2 }} button key="editar-powerbi" component={Link} to={{ pathname: "admin/powerbi" }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <BiBarChartSquare style={{ height: 20, width: 20, color: "#a5a4bf" }} />
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "12px" }}>Editar Dashboard PBI</Typography>} />
                </ListItem>
                <ListItem sx={{ pl: 2 }} button key="admin-squads" component={Link} to={{ pathname: "admin/squads" }}>
                  <ListItemIcon sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                  >
                    <SupervisorAccountIcon fontSize='small' sx={{ color: "#a5a4bf" }} />
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "12px" }}>Gestão de Squads</Typography>} />
                </ListItem>
                <ListItem sx={{ pl: 2 }} button key="user-management" component={Link} to={{ pathname: "admin/user-management" }}>
                  <ListItemIcon sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                  >
                    <PersonAddAltIcon fontSize='small' sx={{ color: "#a5a4bf" }} />
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "12px" }}>Gestão de Usuários</Typography>} />
                </ListItem>
                <ListItem sx={{ pl: 2 }} button key="upload-xp-files" component={Link} to={{ pathname: "admin/upload-xp-files" }}>
                  <ListItemIcon sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                  >
                    <DriveFolderUploadIcon fontSize='small' sx={{ color: "#a5a4bf" }} />
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "12px" }}>Upload XP</Typography>} />
                </ListItem>
              </>}
              {hasTombamentoPermission && <ListItem sx={{ pl: 2 }} button key="reassign-clients" component={Link} to={{ pathname: "admin/reassign-clients" }}>
                <ListItemIcon sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
                >
                  <DriveFolderUploadIcon fontSize='small' sx={{ color: "#a5a4bf" }} />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "12px" }}>Tombamento de clientes</Typography>} />
              </ListItem>}
            </Collapse>
          </>
        )}
        <ListItem sx={{ pl: 2 }} button key="sair" component={Link} to="/" onClick={() => Logout()}>
          <ListItemIcon sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
          }}
          >
            <LogoutIcon sx={{ color: "#a5a4bf" }} />
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>Sair</Typography>} />
        </ListItem>
        <ListItem sx={{ justifyContent: "center" }}>
          {open ? <>
            <Button onClick={handleClickOpen} startIcon={<MdPersonAddAlt />} variant="contained" color="primary" sx={{ width: 150 }}>
              Novo Lead
            </Button>
          </> :
            <>
              <Avatar variant="rounded" sx={{ bgcolor: "#E24BAC" }}>
                <IconButton onClick={handleClickOpen}>
                  <MdPersonAddAlt style={{ color: "#ffffff" }} />
                </IconButton>
              </Avatar>
            </>
          }
        </ListItem>
      </List >


      <DialogNovoLeadComponent open={openDialogNewLead} onClose={handleClose} />
    </>
  )
}

export default SideBarComponent;