import ClientesService from "../../../../Services/ClientesService";
import { useEffect, useState } from "react";
import StorageHelper from "../../../../Services/Helpers/StorageHelper";
import Box from '@mui/material/Box';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import { useUserInfos } from "../../../../Hooks/useUserInfos";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { interfaceClient } from "../../../../Interface/interfaceClient";
import { TextField, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import DataGridComponent from '../Components/DataGridComponent';
import { LeadShowAdvisors } from "../Components/LeadShowAdvisors";
import { CircularProgress, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClientMirrorService from "../../../../Services/ClientMirrorService";

const columnsToShow: any = ['NomeCompleto', 'TelefoneContatoPrimario', 'Preco', 'CodigoCliente', 'UltimaAtividade'];

export const ClientesLista = () => {
  const clientesService = new ClientesService()
  const clientMirrorService = new ClientMirrorService();

  const [allClientes, setAllClientes] = useState<interfaceClient[]>([]);
  const [clientes, setClientes] = useState<interfaceClient[]>([]);
  const [loading, setLoading] = useState(true);
  const { userInfos } = useUserInfos();
  const [statusFiltered, setStatusFiltered] = useState<'OWNER' | 'MIRROR'>('OWNER');
  const [filterFeeFixo, setFilterFeeFixo] = useState(false);

  const storageHelper = new StorageHelper();

  async function fetchAndSetClientes(filterFeeFixo: boolean, assessorId: string) {
    setLoading(true);
    try {
      let clientes;
      if (filterFeeFixo) {
        clientes = await clientesService.ListarClientesComFeeFixo(assessorId);
      } else {
        clientes = await clientesService.ListarClientes(assessorId, "CLIENTE");
      }
      setClientesOrderByLastActivity(clientes);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchAndSetClientesMirror(assessorId: string) {
    setLoading(true);
    try {
      const response = await clientMirrorService.ListClientsMirror(assessorId);
      const clientes = response.Espelhados
        .filter(v => v.Cliente.Status === 'CLIENTE')
        .map(v => v.Cliente);
      setClientesOrderByLastActivity(clientes);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function setClientesOrderByLastActivity(clientes: interfaceClient[]) {
    const sorted = clientes.sort((a, b) => new Date(a.UltimaAtividade).getTime() - new Date(b.UltimaAtividade).getTime());
    setClientes(sorted);
    setAllClientes(sorted);
  }

  useEffect(() => {
    const assessorId = storageHelper.GetUsuarioId();
    
    if (statusFiltered === 'OWNER') {
      fetchAndSetClientes(filterFeeFixo, assessorId);
    } else {
      fetchAndSetClientesMirror(assessorId);
    }    
  }, [statusFiltered, filterFeeFixo]);

  const [countList, setCountList] = useState({ count: 0, priceTotal: 0 })
  useEffect(() => {
    if (clientes.length > 0) {
      let countValue = 0
      let priceValue = 0
      clientes.map((cliente: any) => {
        countValue++
        if (cliente.Preco && cliente.Preco > 0) priceValue += cliente.Preco
      })
      countList.count = countValue
      countList.priceTotal = priceValue
      setCountList({ ...countList })
    }
  }, [clientes])

  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(true);
    const timeoutId = setTimeout(() => {
      setSearchTerm(search);
      setIsSearching(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      setIsSearching(false);
    };
  }, [search]);

  useEffect(() => {
    const filterClients = (client: interfaceClient) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        (client.NomeCompleto?.toLowerCase().includes(searchLower) || false) ||
        (client.TelefoneContatoPrimario?.toLowerCase().includes(searchLower) || false) ||
        (client.Preco?.toString().toLowerCase().includes(searchLower) || false) ||
        (client.CodigoCliente?.toLowerCase().includes(searchLower) || false) ||
        (client.UltimaAtividade && new Date(client.UltimaAtividade).toLocaleString().toLowerCase().includes(searchLower)) || false
      );
    };

    if (searchTerm) {
      const filtered = allClientes.filter(filterClients);
      setClientes(filtered);
    } else {
      setClientes(allClientes);
    }
  }, [searchTerm, allClientes]);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h3">Clientes</Typography>
          { statusFiltered === 'OWNER' && <Tooltip title='Mostrar apenas clientes com FeeFixo'>
              <FormControlLabel
                sx={{ ml: 5 }}
                control={
                  <Checkbox
                    size='small'
                    checked={filterFeeFixo}
                    onChange={(e) => setFilterFeeFixo(e.target.checked)}
                  />
                }
                label="FeeFixo"
              />
            </Tooltip> }
        </Box>
        <Stack justifyContent="center" alignItems="center" direction="row" spacing={6} sx={{ px: 5, backgroundColor: "#fff", borderRadius: 1, height: "80px" }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <WorkOutlineIcon sx={{ color: "#bcbbce" }} />
            <Typography variant="h5">{countList.count}</Typography>
          </Stack>
          <Typography variant="h5" color="#5acea4">R$ {countList.priceTotal.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
        </Stack>
      </Box>
      <Box sx={{
        width: '100%',
        backgroundColor: "#ffffff",
        borderRadius: 1,
        p: 3,
        "& .header-class": {
          fontWeight: "bold"
        },
        "& .cell-class": {
          cursor: "pointer"
        }
      }}>
        {
          userInfos && !userInfos.SquadAdmin && <Box
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <TextField
              label='Pesquisar'
              sx={{ width: 250 }}
              autoComplete='off'
              size='small'
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: isSearching && (
                  <InputAdornment position='end'>
                    <CircularProgress size={15} />
                  </InputAdornment>
                )
              }}
            />
            <ToggleButtonGroup
              size='small'
              color='primary'
              value={statusFiltered}
              disabled={loading}
              exclusive
              onChange={(_, newValue) => newValue ? setStatusFiltered(newValue) : null}
              sx={{ ml: 3 }}
            >
              <ToggleButton value='OWNER' sx={{ height: 30 }}>
                Meus clientes
              </ToggleButton>
              <ToggleButton value='MIRROR' sx={{ height: 30 }}>
                Clientes compartilhados
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        }
        {
          userInfos && userInfos.SquadAdmin ? <LeadShowAdvisors status='CLIENTE' dataGridColumnsToShow={columnsToShow} /> :
            <DataGridComponent loading={loading} columns={columnsToShow} clientes={clientes} />
        }
      </Box>
    </>
  )
}