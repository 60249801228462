import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { FormCambio } from './FormCambio';
import { FormConsorcio } from './FormConsorcio';
import { Divider, Typography } from '@mui/material';

interface DialogNovoNegocioProps {
  open: boolean;
  onClose: () => void;
}

export const DialogNovoNegocioComponent: React.FC<DialogNovoNegocioProps> = ({ open, onClose }) => {
  const [tipoNegocio, setTipoNegocio] = useState<string>('cambio');

  const handleTipoNegocioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoNegocio(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle variant="h3">Novo Negócio</DialogTitle>
      <DialogContent>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <Typography variant='h4'>Tipo de Negócio</Typography>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup row value={tipoNegocio} onChange={handleTipoNegocioChange}>
            <FormControlLabel value="cambio" control={<Radio />} label="Câmbio" />
            <FormControlLabel value="consorcio" control={<Radio />} label="Consórcio" />
            <FormControlLabel value="seguros" control={<Radio />} label="Seguros" disabled />
            <FormControlLabel value="plano-de-saude" control={<Radio />} label="Plano de Saúde" disabled />
            <FormControlLabel value="credito" control={<Radio />} label="Crédito" disabled />
            <FormControlLabel value="pps" control={<Radio />} label="PPS" disabled />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ mb: 4, mt: 2 }} />
        {tipoNegocio === 'cambio' && <FormCambio onClose={onClose} />}
        {tipoNegocio === 'consorcio' && <FormConsorcio onClose={onClose} />}
      </DialogContent>
    </Dialog>
  );
};
