import React, { useEffect, useState } from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClientesService from "../../../../Services/ClientesService";
import DataGridComponent from "./DataGridComponent";
import StorageHelper from "../../../../Services/Helpers/StorageHelper";
import { useUserInfos } from "../../../../Hooks/useUserInfos";
import { interfaceClient } from "../../../../Interface/interfaceClient";
import { CircularProgress, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClientMirrorService from "../../../../Services/ClientMirrorService";

interface props {
  dataGridColumnsToShow: any;
  status: 'LEAD' | 'QUALIFICACAO' | 'CLIENTE' | 'PERDEU' | 'PAUSADO' | 'NEGOCIO';
}

export const LeadShowAdvisors: React.FC<props> = ({ dataGridColumnsToShow, status }) => {
  const clientesService = new ClientesService()
  const clientMirrorService = new ClientMirrorService();
  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();

  const { squadMembers } = useUserInfos()

  const [advisorToShowId, setAdvisorToShowId] = useState(assessorId);
  const [clientes, setClientes] = useState<interfaceClient[]>([])
  const [allClientes, setAllClientes] = useState<interfaceClient[]>([]);
  const [loading, setLoading] = useState(false)
  const [statusFiltered, setStatusFiltered] = useState<'OWNER' | 'MIRROR'>('OWNER');

  const handleChange = (event: SelectChangeEvent) => {
    setClientes([]);
    setAdvisorToShowId(event.target.value as string);
  };

  useEffect(() => {
    if (advisorToShowId !== '') {
      setLoading(true)

      if (statusFiltered === 'OWNER') {
        clientesService.ListarClientes(advisorToShowId, status)
          .then((e: any) => {
            const clientsSorted = e.sort((a: any, b: any) => {
              return (new Date(a.UltimaAtividade).getTime() - new Date(b.UltimaAtividade).getTime())
            })
            setAllClientes(clientsSorted);
            setClientes(clientsSorted);
          })
          .catch(err => console.error(err))
          .finally(() => setLoading(false));
      } else {
        clientMirrorService.ListClientsMirror(advisorToShowId)
          .then((response) => {
            const filtered = response.Espelhados
              .filter(v => v.Cliente.Status === 'CLIENTE')
              .map(v => v.Cliente)
              .sort((a: any, b: any) => {
                return (new Date(a.UltimaAtividade).getTime() - new Date(b.UltimaAtividade).getTime())
              });
            setClientes(filtered);
            setAllClientes(filtered);
          })
          .catch(err => console.error(err))
          .finally(() => setLoading(false));
      }
    }
  }, [advisorToShowId, statusFiltered]);

  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(true);
    const timeoutId = setTimeout(() => {
      setSearchTerm(search);
      setIsSearching(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      setIsSearching(false);
    };
  }, [search]);

  useEffect(() => {
    const filterClients = (client: interfaceClient) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        (client.NomeCompleto?.toLowerCase().includes(searchLower) || false) ||
        (client.TelefoneContatoPrimario?.toLowerCase().includes(searchLower) || false) ||
        (client.Preco?.toString().toLowerCase().includes(searchLower) || false) ||
        (client.CodigoCliente?.toLowerCase().includes(searchLower) || false) ||
        (client.UltimaAtividade && new Date(client.UltimaAtividade).toLocaleString().toLowerCase().includes(searchLower)) || false
      );
    };

    if (searchTerm) {
      const filtered = allClientes.filter(filterClients);
      setClientes(filtered);
    } else {
      setClientes(allClientes);
    }
  }, [searchTerm, allClientes]);


  return <>
    <Box>
      <FormControl sx={{ minWidth: 400 }} size='small'>
        <InputLabel id="label-show-advisor">Selecione algum assessor para visualizar os Leads</InputLabel>
        <Select
          labelId="label-show-advisor"
          id="select-show-advisor"
          value={advisorToShowId}
          label="Selecione algum assessor para visualizar os Leads"
          onChange={handleChange}
        >
          {
            squadMembers.map((v: any, i: number) => {
              return (
                <MenuItem key={'id-' + i} value={v.UsuarioId}>
                  <span style={{ fontWeight: v.UsuarioId === assessorId ? 'bold' : 'normal' }}>
                    {`${v.Usuario.Nome} ${v.Usuario.Sobrenome} - ${v.Usuario.CodigoAssessor}`}
                  </span>
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      <TextField
        label='Pesquisar'
        sx={{ width: 250, ml: 3 }}
        autoComplete='off'
        size='small'
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: isSearching && (
            <InputAdornment position='end'>
              <CircularProgress size={15} />
            </InputAdornment>
          )
        }}
      />
      <ToggleButtonGroup
        size='small'
        color='primary'
        value={statusFiltered}
        disabled={loading}
        exclusive
        onChange={(_, newValue) => newValue ? setStatusFiltered(newValue) : null}
        sx={{ ml: 3 }}
      >
        <ToggleButton value='OWNER' sx={{ height: 30 }}>
          {assessorId === advisorToShowId ? 'Meus clientes' : 'Clientes'}
        </ToggleButton>
        <ToggleButton value='MIRROR' sx={{ height: 30 }}>
          Clientes espelhados
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
    <DataGridComponent loading={loading} columns={dataGridColumnsToShow} clientes={clientes} />
  </>
}