import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from "react";
import NegociosService from "../Services/NegociosService";
import UserService from "../Services/UserService";
import { enqueueSnackbar } from "notistack";
import { DropResult } from "react-beautiful-dnd";
import StorageHelper from '../Services/Helpers/StorageHelper';
import { INegocio, INegocioConsorcioRequest } from "../Interface/interfaceNegocios";
import { usePermissions } from "./usePermissionsUser";
import { createTask } from "../Components/Common/NewProfileClient/crudTask";
import { formattingDateToISO } from "../Components/Common/NewProfileClient/usefulFunctions";
import { interfaceSchedule } from "../Interface/intarfaceSchedule";

export type CategoriaKanbanConsorcio = 'lead' | 'r1' | 'outrasReunioes' | 'aguardandoFechamento' | 'cliente';

const statusMapping: Record<CategoriaKanbanConsorcio, string> = {
  lead: "LEAD",
  r1: "R1",
  outrasReunioes: "OUTRAS_REUNIOES",
  aguardandoFechamento: "AGUARDANDO_FECHAMENTO",
  cliente: "CLIENTE",
};

const droppableIdToName: Record<CategoriaKanbanConsorcio, string> = {
  lead: 'Lead',
  r1: 'R1',
  outrasReunioes: 'Outras Reuniões',
  aguardandoFechamento: 'Aguardando Fechamento',
  cliente: 'Cliente'
};

export interface IKanbanList {
  lead: {
    name: string;
    data: INegocioConsorcioRequest[];
  };
  r1: {
    name: string;
    data: INegocioConsorcioRequest[];
  };
  outrasReunioes: {
    name: string;
    data: INegocioConsorcioRequest[];
  };
  aguardandoFechamento: {
    name: string;
    data: INegocioConsorcioRequest[];
  };
  cliente: {
    name: string;
    data: INegocioConsorcioRequest[];
  };
}

const useKanbanNegociosConsorcios = () => {
  const negociosService = new NegociosService();
  const userService = new UserService();
  const storageHelper = new StorageHelper();
  const vendedorId = storageHelper.GetUsuarioId();
  const { permissions } = usePermissions();

  const [kanbanList, setKanbanList] = useState<IKanbanList>({
    lead: { name: 'Lead', data: [] },
    r1: { name: 'R1', data: [] },
    outrasReunioes: { name: 'Outras Reuniões', data: [] },
    aguardandoFechamento: { name: 'Aguardando Fechamento', data: [] },
    cliente: { name: 'Cliente', data: [] },
  });

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [usuarioId, setUsuarioId] = useState<string | null>(null);
  const [users, setUsers] = useState<{ id: string, label: string }[]>([]);

  const isAdminConsorcio = useMemo(() => {
    return permissions.some(permission => (permission.PermissionName === 'AdminConsórcio')) || storageHelper.GetUsuarioRole() === 'Administrador';
  }, [permissions]);

  const fetchUsersByPermission = async () => {
    if (isAdminConsorcio) {
      try {
        const fetchedUsers = await userService.ListUsersByPermissions(['Consórcio']);
        const formattedUsers = fetchedUsers.map(user => ({
          id: user.Id,
          label: user.NomeCompleto,
        }));

        setUsers([...formattedUsers]);
      } catch (error) {
        enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
      }
    }
  };

  const fetchNegocios = async () => {
    setLoading(true);
    try {
      const negocios = await negociosService.ListNegociosConsorciosByVendedorId(usuarioId || vendedorId);

      const kanban: IKanbanList = {
        lead: { name: 'Lead', data: [] },
        r1: { name: 'R1', data: [] },
        outrasReunioes: { name: 'Outras Reuniões', data: [] },
        aguardandoFechamento: { name: 'Aguardando Fechamento', data: [] },
        cliente: { name: 'Cliente', data: [] },
      };

      negocios.forEach(negocio => {
        const statusKey = Object.keys(statusMapping).find(key => statusMapping[key as keyof typeof statusMapping] === negocio.Negocio.Status);
        if (statusKey) {
          kanban[statusKey as keyof typeof statusMapping].data.push(negocio);
        }
      });

      setKanbanList(kanban);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar negócios.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNegocios();
  }, [usuarioId]);

  useEffect(() => {
    fetchUsersByPermission();
  }, [isAdminConsorcio]);

  const filteredKanbanList = useMemo(() => {
    if (!searchTerm) {
      return Object.fromEntries(
        Object.entries(kanbanList).map(([key, column]) => [
          key,
          {
            ...column,
            data: column.data.sort((a: INegocioConsorcioRequest, b: INegocioConsorcioRequest) => new Date(b.Negocio.DataInicio).getTime() - new Date(a.Negocio.DataInicio).getTime())
          }
        ])
      ) as IKanbanList;
    }

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const filterData = (negocios: INegocioConsorcioRequest[]) =>
      negocios
        .filter(negocio =>
          negocio.NomeCliente?.toLowerCase().includes(lowercasedSearchTerm)
        )
        .sort((a, b) => new Date(b.Negocio.DataInicio).getTime() - new Date(a.Negocio.DataInicio).getTime());

    return Object.fromEntries(
      Object.entries(kanbanList).map(([key, column]) => [
        key,
        {
          ...column,
          data: filterData(column.data)
        }
      ])
    ) as IKanbanList;
  }, [searchTerm, kanbanList]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || destination.droppableId === source.droppableId) return;

    const startColumnData = kanbanList[source.droppableId as keyof typeof statusMapping].data;
    const finishColumnData = kanbanList[destination.droppableId as keyof typeof statusMapping].data;

    const negocioMoved = startColumnData.find(negocio => negocio.Negocio.Id === draggableId);
    if (!negocioMoved) return;

    if (negocioMoved.Negocio.VendedorId !== vendedorId) {
      enqueueSnackbar('Você não tem permissão para mover este negócio.', { variant: 'error' });
      return;
    }

    const newStatus = statusMapping[destination.droppableId as keyof typeof statusMapping];

    const prevKanbanList = { ...kanbanList };

    setKanbanList(prevState => {
      const newStartColumnData = startColumnData.filter(negocio => negocio.Negocio.Id !== draggableId);
      const updatedNegocioMoved = { ...negocioMoved, Negocio: { ...negocioMoved.Negocio, Status: newStatus } };
      const newFinishColumnData = [updatedNegocioMoved, ...finishColumnData];

      return {
        ...prevState,
        [source.droppableId]: {
          ...prevState[source.droppableId as keyof typeof statusMapping],
          data: newStartColumnData,
        },
        [destination.droppableId]: {
          ...prevState[destination.droppableId as keyof typeof statusMapping],
          data: newFinishColumnData,
        }
      };
    });

    try {
      const updateData: Partial<INegocio> = {
        Id: negocioMoved.Negocio.Id,
        Status: newStatus as INegocio['Status']
      };

      if (destination.droppableId === 'cliente') {
        updateData.DataFechamento = new Date();
      }

      await negociosService.UpdateNegocio(updateData);

      const now = new Date();
      const descricao = `Movido de ${droppableIdToName[source.droppableId as CategoriaKanbanConsorcio]} para ${droppableIdToName[destination.droppableId as CategoriaKanbanConsorcio]}.`;

      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: 'Movimentação em Consórcio',
        Descricao: descricao,
        AssessorUsuarioId: vendedorId,
        ClienteId: negocioMoved.Negocio.ClienteId!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: 'Consórcio'
      };

      await createTask(newActivity);
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar status.', { variant: 'error' });

      setKanbanList(prevKanbanList);
    }
  };

  return {
    kanbanList,
    onDragEnd,
    setKanbanList,
    loading,
    fetchNegocios,
    filteredKanbanList,
    setSearchTerm,
    users,
    setUsuarioId,
    usuarioId,
    isAdminConsorcio,
  };
};

interface KanbanNegociosContextProps {
  kanbanList: IKanbanList;
  setKanbanList: React.Dispatch<React.SetStateAction<IKanbanList>>;
  onDragEnd: (result: DropResult) => Promise<void>;
  loading: boolean;
  fetchNegocios: () => Promise<void>;
  filteredKanbanList: IKanbanList;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  users: { id: string, label: string }[];
  setUsuarioId: React.Dispatch<React.SetStateAction<string | null>>;
  usuarioId: string | null;
  isAdminConsorcio: boolean;
}

const KanbanNegociosConsorciosContext = createContext<KanbanNegociosContextProps | undefined>(undefined);

export const KanbanNegociosConsorciosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kanbanNegociosConsorcios = useKanbanNegociosConsorcios();

  return (
    <KanbanNegociosConsorciosContext.Provider value={kanbanNegociosConsorcios}>
      {children}
    </KanbanNegociosConsorciosContext.Provider>
  );
};

export const useKanbanNegociosConsorciosContext = () => {
  const context = useContext(KanbanNegociosConsorciosContext);
  if (!context) {
    throw new Error('useKanbanNegociosContext must be used within a KanbanNegociosProvider');
  }
  return context;
};