import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, Grid, FormControl, TextField,
  MenuItem, Select, InputLabel, Checkbox, ListItemText, OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  SelectChangeEvent
} from '@mui/material';
import { useClientContext } from '../ClientContext';
import { useInsuranceUsers } from '../../../../Hooks/useInsuranceUsers';
import SendIcon from '@mui/icons-material/SendOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import ScheduleService from '../../../../Services/ScheduleService';
import StorageHelper from '../../../../Services/Helpers/StorageHelper';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { interfaceSchedule } from '../../../../Interface/intarfaceSchedule';
import { interfaceClient } from '../../../../Interface/interfaceClient';
import { NumberFormatCustom } from '../../../../Helpers/Components';
import ClientesService from '../../../../Services/ClientesService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const interestOptions = [
  'SegurosDeVida',
  'PlanoDeSaude',
  'PlanejamentoPatrimonial',
  'Outros'
];

const interestLabels: { [key: string]: string } = {
  SegurosDeVida: 'Seguros De Vida',
  PlanoDeSaude: 'Plano De Saúde',
  PlanejamentoPatrimonial: 'Planejamento Patrimonial',
  Outros: 'Outros'
};

interface DialogInsuranceComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export interface IClienteSegurosRequest {
  ClienteId: string;
  UsuarioIdSeguros: string;
  DataNascimento: Date | null;
  StatusSeguros: string;
  PossuiSeguros: boolean;
  InteresseSegurosDeVida: boolean;
  InteressePlanoDeSaude: boolean;
  InteressePlanejamentoPatrimonial: boolean;
  InteresseOutros: boolean;
  Profissao: string;
  RendaBrutaMensal: number | null;
  DespesaMensal: number | null;
  PatrimonioEstimado: number | null;
  AporteMensal: number | null;
  ConfiguracaoFamiliar: string;
  DescricaoSeguros: string;
}

export const InsuranceDialog: React.FC<DialogInsuranceComponentProps> = ({ open, setOpen }) => {
  const storageHelper = new StorageHelper();
  const clientesService = new ClientesService;
  const scheduleService = new ScheduleService();
  const assessorId = storageHelper.GetUsuarioId();

  const { client, updateClient, reloadClientData } = useClientContext();
  const { activities, setActivities } = useClientContext();
  const { insuranceUsers, refetch } = useInsuranceUsers();

  const [selectedOption, setSelectedOption] = useState<'sendToInsurance' | 'recordInvested'>('sendToInsurance');
  const [description, setDescription] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);

  const initialInsuranceRequest: IClienteSegurosRequest = {
    ClienteId: client?.Id || '',
    UsuarioIdSeguros: '',
    StatusSeguros: 'LEAD',
    DataNascimento: null,
    PossuiSeguros: false,
    InteresseSegurosDeVida: false,
    InteressePlanoDeSaude: false,
    InteressePlanejamentoPatrimonial: false,
    InteresseOutros: false,
    Profissao: client?.Profissao || '',
    RendaBrutaMensal: null,
    DespesaMensal: null,
    PatrimonioEstimado: null,
    AporteMensal: null,
    ConfiguracaoFamiliar: '',
    DescricaoSeguros: ''
  };

  const [insuranceRequest, setInsuranceRequest] = useState<IClienteSegurosRequest>(initialInsuranceRequest);

  useEffect(() => {
    if (open && (client?.StatusSeguros === 'CANCELADO' || client?.StatusSeguros === 'PERDEU')) {
      enqueueSnackbar('O cliente já foi removido da área de seguros.', { variant: 'warning' });
      setOpen(false);
      return;
    }

    setSelectedOption('sendToInsurance');
    setCancellationReason('');
    setSelectedInterests([]);
    setInsuranceRequest(initialInsuranceRequest);

    if (insuranceUsers.length === 1) {
      setSelectedUser(insuranceUsers[0].Id);
    } else {
      setSelectedUser('');
    }
  }, [open, insuranceUsers]);

  useEffect(() => {
    if (open && insuranceUsers.length > 0) {
      refetch();
    }
  }, [open, refetch, insuranceUsers.length]);

  const handleChange = (key: keyof IClienteSegurosRequest, value: any) => {
    setInsuranceRequest({ ...insuranceRequest, [key]: value });
  };

  const handleInterestChange = (event: SelectChangeEvent<typeof selectedInterests>) => {
    const {
      target: { value },
    } = event;
    setSelectedInterests(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleUpdateInfos = async () => {
    setLoadingSave(true);

    if (!selectedUser && !client?.StatusSeguros) {
      enqueueSnackbar('Todos os campos são obrigatórios.', { variant: 'error' });
      setLoadingSave(false);
      return;
    }

    if (client?.StatusSeguros) {
      const newStatus = client.StatusSeguros === 'EMITIDO' ? 'CANCELADO' : 'PERDEU';

      updateClient({
        ...client,
        StatusSeguros: newStatus,
        UsuarioIdSeguros: undefined,
        MotivoRemocaoSeguros: cancellationReason
      })
        .then(() => {
          reloadClientData();
          setOpen(false);
        })
        .catch((error) => {
          let errorMessage = 'Erro ao atualizar informações do cliente.';
          if (error instanceof Error) {
            errorMessage = error.message;
          }
          enqueueSnackbar(errorMessage, { variant: 'error' });
        })
        .finally(() => setLoadingSave(false));
    } else {
      const statusMap: Record<string, interfaceClient['StatusSeguros']> = {
        recordInvested: 'EMITIDO',
        sendToInsurance: 'LEAD',
      };
      const newStatus = statusMap[selectedOption];

      const payload: IClienteSegurosRequest = {
        ClienteId: insuranceRequest.ClienteId,
        UsuarioIdSeguros: selectedUser,
        DataNascimento: insuranceRequest.DataNascimento,
        PossuiSeguros: insuranceRequest.PossuiSeguros,
        StatusSeguros: newStatus ? newStatus : 'LEAD',
        InteresseSegurosDeVida: selectedInterests.includes('SegurosDeVida'),
        InteressePlanoDeSaude: selectedInterests.includes('PlanoDeSaude'),
        InteressePlanejamentoPatrimonial: selectedInterests.includes('PlanejamentoPatrimonial'),
        InteresseOutros: selectedInterests.includes('Outros'),
        Profissao: insuranceRequest.Profissao,
        RendaBrutaMensal: insuranceRequest.RendaBrutaMensal,
        DespesaMensal: insuranceRequest.DespesaMensal,
        PatrimonioEstimado: insuranceRequest.PatrimonioEstimado,
        AporteMensal: insuranceRequest.AporteMensal,
        ConfiguracaoFamiliar: insuranceRequest.ConfiguracaoFamiliar,
        DescricaoSeguros: insuranceRequest.DescricaoSeguros
      };

      try {
        await clientesService.EnviarClienteParaSeguros(payload);
        enqueueSnackbar('Informações do cliente atualizadas com sucesso.', { variant: 'success' });
        reloadClientData();
        setOpen(false);
      } catch (error: unknown) {
        let errorMessage = 'Erro ao enviar cliente para seguros.';
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } finally {
        setLoadingSave(false);
      }
    }
  };

  const handleCancel = () => {
    setDescription('');
    setSelectedOption('sendToInsurance');
    setSelectedUser('');
    setCancellationReason('');
    setSelectedInterests([]);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle variant='h4'>{client?.StatusSeguros ? 'Remover da Área de Seguros' : 'Enviar para Área de Seguros'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
          {client?.StatusSeguros ? (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Descreva o motivo de Cancelamento ou Não Fechamento"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={cancellationReason}
                  onChange={(e) => setCancellationReason(e.target.value)}
                  inputProps={{
                    maxLength: 500
                  }}
                  helperText={`${cancellationReason.length}/500 caracteres restantes`}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  color="primary"
                  value={selectedOption}
                  exclusive
                  onChange={(_, value) => value && setSelectedOption(value)}
                  fullWidth
                >
                  <ToggleButton value="sendToInsurance">Enviar para Área de Seguros</ToggleButton>
                  <ToggleButton value="recordInvested">Já possui seguros</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <MobileDatePicker
                    label="Data de Nascimento"
                    value={insuranceRequest.DataNascimento}
                    onChange={(newValue: any) => handleChange('DataNascimento', newValue)}
                    renderInput={(params: any) => <TextField fullWidth {...params} size="small" />}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="interests-label">Interesses</InputLabel>
                  <Select
                    labelId="interests-label"
                    id="interests"
                    multiple
                    value={selectedInterests}
                    onChange={handleInterestChange}
                    input={<OutlinedInput label="Interesses" />}
                    renderValue={(selected) => selected.map(value => interestLabels[value]).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {interestOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox checked={selectedInterests.indexOf(option) > -1} />
                        <ListItemText primary={interestLabels[option]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Configuração familiar"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={insuranceRequest.ConfiguracaoFamiliar}
                  inputProps={{
                    maxLength: 500
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('ConfiguracaoFamiliar', e.target.value)}
                  helperText={`${insuranceRequest.ConfiguracaoFamiliar.length}/500 caracteres restantes`}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="select-insurance-user-label">Life Planner</InputLabel>
                  <Select
                    labelId="select-insurance-user-label"
                    label="Life Planner"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value as string)}
                  >
                    {insuranceUsers.map((user) => (
                      <MenuItem key={user.Id} value={user.Id}>{user.NomeCompleto}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} textAlign='center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={insuranceRequest.PossuiSeguros}
                      onChange={(e) => handleChange('PossuiSeguros', e.target.checked)}
                    />
                  }
                  label="Possui Seguros"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  label="Profissão"
                  value={insuranceRequest.Profissao}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('Profissao', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Renda Bruta Mensal"
                  autoComplete="off"
                  value={insuranceRequest.RendaBrutaMensal || ''}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange('RendaBrutaMensal', Number(e.target.value))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Despesa Mensal"
                  autoComplete="off"
                  value={insuranceRequest.DespesaMensal || ''}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange('DespesaMensal', Number(e.target.value))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Patrimônio Estimado"
                  autoComplete="off"
                  value={insuranceRequest.PatrimonioEstimado || ''}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange('PatrimonioEstimado', Number(e.target.value))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Aporte Mensal"
                  autoComplete="off"
                  value={insuranceRequest.AporteMensal || ''}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange('AporteMensal', Number(e.target.value))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={insuranceRequest.DescricaoSeguros}
                  inputProps={{
                    maxLength: 500
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('DescricaoSeguros', e.target.value)}
                  helperText={`${insuranceRequest.DescricaoSeguros.length}/500 caracteres restantes`}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} textAlign="center">
            <Button
              color="secondary"
              onClick={handleCancel}
              size='small'
            >
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size='small'
              endIcon={client?.StatusSeguros ? <DeleteIcon /> : <SendIcon />}
              onClick={handleUpdateInfos}
              loading={loadingSave}
              sx={{ ml: 2 }}
            >
              {client?.StatusSeguros ? 'Remover de seguros' : 'Enviar para seguros'}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
